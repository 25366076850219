// import React from "react";
// import { Link } from "react-router-dom";

// const Homeblog = () => {
//   return (
//     <div>
//       <section class="ftco-section">
//         <div class="container">
//           <div class="row justify-content-center mb-5 pb-3">
//             <div class="col-md-7 heading-section  text-center">
//               <h2 class="mb-4">Recent from blog</h2>
//               <p>
//                 Far far away, behind the word mountains, far from the countries
//                 Vokalia and Consonantia, there live the blind texts.
//               </p>
//             </div>
//           </div>
//           <div class="row d-flex">
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_1.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_2.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <Link
//                   to="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/image_3.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                   }}
//                 ></Link>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <Link to="#">Sept 10, 2018</Link>
//                     </div>
//                     <div>
//                       <Link to="#">Admin</Link>
//                     </div>
//                     <div>
//                       <Link to="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </Link>
//                     </div>
//                   </div>
//                   <h3 class="heading mt-3">
//                     <Link to="#">Hurricane Irma has devastated Florida</Link>
//                   </h3>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Homeblog;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Homeblog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get("https://weplayfortheworld.org/api/api/blogs")
      .then((response) => {
        setBlogs(response.data.slice(0, 3)); // Only get the latest 3 blogs
      })
      .catch((error) => console.error("Error fetching blog data:", error));
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7 heading-section text-center">
            <h2 className="mb-4">Recent from blog</h2>
          </div>
        </div>
      </div>

      {/* Blog List Section */}
      <section className="ftco-section" style={{ padding: " 3em 0" }}>
        <div className="container">
          <div className="row d-flex">
            {blogs.map((blog) => (
              <div className="col-md-4 d-flex" key={blog.id}>
                <div className="blog-entry align-self-stretch">
                  <Link
                    to={`/Blogdetail/${blog.id}`}
                    className="block-20"
                    style={{
                      backgroundImage: `url(${blog.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "250px",
                    }}
                  ></Link>
                  <div className="text p-4 d-block">
                    <div className="meta mb-3">
                      <div>
                        <Link to="#">{blog.date}</Link>
                      </div>
                      <div>
                        <Link to="#">Admin</Link>
                      </div>
                    </div>
                    <h3 className="heading mt-3">
                      <Link to={`/Blogdetail/${blog.id}`}>{blog.title}</Link>
                    </h3>
                    <p>
                      {blog.hint.length > 60
                        ? `${blog.hint.substring(0, 60)}...`
                        : blog.hint}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homeblog;
