// import Form from "./Form";
import Images from "./Images";

import React from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Hero from "./Hero";
import EventCarousel from "./Eventcrousel";
import Homeblog from "./Homeblog";

const Home = () => {
  return (
    <div className="home-page">
      <Hero />

      <EventCarousel />

      <Homeblog />
      <div class="row justify-content-center">
        <div class="col-md-7 heading-section  text-center">
          <h2 class="mb-4">Gallery</h2>
        </div>
      </div>
      <Images />
      {/* 
      <Form /> */}
    </div>
  );
};

export default Home;
