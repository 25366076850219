// import React from "react";
// import { Container, Row, Col, Card } from "react-bootstrap";

// const Team = () => {
//   const teamMembers = [
//     {
//       id: 1,
//       name: "Urshila Chanana",
//       image: "../assets/images/urshila.jpg",
//     },
//     {
//       id: 2,
//       name: "Radhika Chanana",
//       image: "../assets/images/radhika.jpg",
//     },
//     {
//       id: 3,
//       name: "Amira Chanana",
//       image: "../assets/images/amira.jpg",
//     },
//     {
//       id: 4,
//       name: "Jaaikaran Chanana",
//       image: "../assets/images/jaaikaran.jpg",
//     },
//   ];

//   return (
//     <>
//       <div
//         className="hero-wrap"
//         style={{
//           backgroundImage: `url(../assets/images/s-2.jpg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//         }}
//         data-stellar-background-ratio="0.5"
//       >
//         <div className="overlay"></div>
//         <div className="container">
//           <div
//             style={{
//               backgroundColor: "rgba(0,0,0,0.5)",
//               position: "absolute",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//             }}
//           ></div>
//           <div
//             className="row no-gutters slider-text align-items-center justify-content-center"
//             data-scrollax-parent="true"
//           >
//             <div
//               className="col-md-7 text-center"
//               data-scrollax=" properties: { translateY: '70%' }"
//             >
//               <p
//                 className="breadcrumbs"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 <span className="mr-2">
//                   <a href="index.html">Home</a>
//                 </span>
//                 <span>Our Team</span>
//               </p>
//               <h1
//                 className="mb-3 bread"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 Our Team
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Container>
//         <h2 className="text-center my-4">Meet Our Team</h2>

//         <Row>
//           {teamMembers.map((member) => (
//             <Col key={member.id} md={3} className="mb-4">
//               <Card>
//                 <Card.Img
//                   variant="top"
//                   src={member.image}
//                   style={{ height: "250px", objectFit: "cover" }}
//                 />
//                 <Card.Body>
//                   <Card.Title className="text-center">{member.name}</Card.Title>
//                 </Card.Body>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default Team;

// import React, { useState } from "react";
// import { Container, Row, Col, Card } from "react-bootstrap";

// const Team = () => {
//   const [expandedId, setExpandedId] = useState(null);

//   const teamMembers = [
//     {
//       id: 1,
//       name: "Urshila Chanana",
//       image: "../assets/images/urshila.jpg",
//       description:
//         "Dr. Urshila Chanana, Trustee of CWF, is a retired Associate Professor of the Department of English, Indraprastha College, University of Delhi. Dr. Chanana’s passionate engagement with literature is representative of many contemporary social issues that beset the Indian milieu today. She has been deeply involved with the well-being of students from underprivileged sections of society.",
//     },
//     {
//       id: 2,
//       name: "Radhika Chanana",
//       image: "../assets/images/radhika.jpg",
//       description:
//         "Radhika Chanana spearheads the Chanana Welfare Foundation and is the Founding Trustee. She envisions educating, empowering children and women, to propel a change that will bring in self sufficiency, poverty reduction and emancipation. She is a staunch believer in the fact that helping the less fortunate can move the nation towards equality and a bright, self-reliant future.",
//     },
//     {
//       id: 3,
//       name: "Amira Chanana",
//       image: "../assets/images/amira.jpg",
//       description:
//         "Amira Chanana is an Advisor at CWF and helps her mother and grandmother in the work of the foundation. She is young, dynamic and the key force behind CWF. She grew up working with the underprivileged. So she understands the necessities and methods to help reduce the gap between the underprivileged and the privileged.",
//     },
//     {
//       id: 4,
//       name: "Jaaikaran Chanana",
//       image: "../assets/images/jaaikaran.jpg",
//       description:
//         "Jaaikaran Chanana is a Junior Trustee at CWF, who is passionate about football, food, the community and helping others. He was born in 2004, raised in New Delhi before attending Caldicott prep schoolUK, from 2015 to 2018 he is currently attending Wellington College school in the UK and will be graduating in 2023. Jaaikaran believes in the core values echoed by the other trustees. He believes literature, learning and sport all go hand in hand. It is his belief is that educating the youth gives them a larger perspective in life and helps them achieve their future goals. He believes that giving back should be the core value and principle of every single human being that they must encapsulate in their daily life. It brings great joy to Jaaikaran when he sees children enjoying themselves playing sport. Sport removes all boundaries that the class system has put in society as people from all backgrounds and life can join forces together with one thing in the mind that is to have fun and enjoy themselves. Jaaikaran has speer headed the project of educating the youth during the pandemic and helping Care homes. The pandemic was full of uncertainty and fear. Seeing and hearing the plights of many unfortunate children, he worked hard to help those he could. It is the value, that every person matters and the importance of mental wellbeing that laid the path of these projects. Jaaikaran aims to provide a different approach to philanthropy during through the medium of sport, education and learning. He believes that plays an important role in every child's life, whether it is to have fun, to bond with others, to find a passion and a profession.",
//     },
//   ];

//   const toggleDescription = (id) => {
//     setExpandedId(expandedId === id ? null : id);
//   };

//   return (
//     <>
//       <div
//         className="hero-wrap"
//         style={{
//           backgroundImage: `url(../assets/images/s-2.jpg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//         }}
//         data-stellar-background-ratio="0.5"
//       >
//         <div className="overlay"></div>
//         <div className="container">
//           <div
//             className="row no-gutters slider-text align-items-center justify-content-center"
//             data-scrollax-parent="true"
//           >
//             <div className="col-md-7 text-center">
//               <p className="breadcrumbs">
//                 <span className="mr-2">
//                   <a href="index.html">Home</a>
//                 </span>
//                 <span>Our Team</span>
//               </p>
//               <h1 className="mb-3 bread">Our Team</h1>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Container>
//         <h2 className="text-center my-4">Meet Our Team</h2>

//         <Row>
//           {teamMembers.map((member) => (
//             <Col key={member.id} md={3} className="mb-4">
//               <Card>
//                 <Card.Img
//                   variant="top"
//                   src={member.image}
//                   style={{ height: "250px", objectFit: "cover" }}
//                 />
//                 <Card.Body>
//                   <Card.Title
//                     className="text-center"
//                     style={{ cursor: "pointer", color: "#007bff" }}
//                     onClick={() => toggleDescription(member.id)}
//                   >
//                     {member.name}
//                   </Card.Title>
//                   {expandedId === member.id && (
//                     <Card.Text className="mt-2">{member.description}</Card.Text>
//                   )}
//                 </Card.Body>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default Team;

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Team = () => {
  const teamMembers = [
    {
      id: 1,
      name: "Urshila Chanana",
      image: "../assets/images/urshila.jpeg",
      description:
        "Dr. Urshila Chanana, Trustee of CWF, is a retired Associate Professor of the Department of English, Indraprastha College, University of Delhi. Dr. Chanana’s passionate engagement with literature is representative of many contemporary social issues that beset the Indian milieu today. She has been deeply involved with the well-being of students from underprivileged sections of society.",
    },
    {
      id: 2,
      name: "Radhika Chanana",
      image: "../assets/images/radhika.jpg",
      description:
        "Radhika Chanana spearheads the Chanana Welfare Foundation and is the Founding Trustee. She envisions educating, empowering children and women, to propel a change that will bring in self sufficiency, poverty reduction and emancipation. She is a staunch believer in the fact that helping the less fortunate can move the nation towards equality and a bright, self-reliant future.",
    },
    {
      id: 3,
      name: "Amira Chanana",
      image: "../assets/images/amira.jpg",
      description:
        "Amira Chanana is an Advisor at CWF and helps her mother and grandmother in the work of the foundation. She is young, dynamic and the key force behind CWF. She grew up working with the underprivileged. So she understands the necessities and methods to help reduce the gap between the underprivileged and the privileged.",
    },
    {
      id: 4,
      name: "Jaaikaran Chanana",
      image: "../assets/images/jaaikaran.jpeg",
      description:
        "Jaaikaran Chanana is a Junior Trustee at CWF, who is passionate about football, food, the community and helping others. He was born in 2004, raised in New Delhi before attending Caldicott prep schoolUK, from 2015 to 2018 he is currently attending Wellington College school in the UK and will be graduating in 2023. Jaaikaran believes in the core values echoed by the other trustees. He believes literature, learning and sport all go hand in hand. It is his belief is that educating the youth gives them a larger perspective in life and helps them achieve their future goals. He believes that giving back should be the core value and principle of every single human being that they must encapsulate in their daily life. It brings great joy to Jaaikaran when he sees children enjoying themselves playing sport. Sport removes all boundaries that the class system has put in society as people from all backgrounds and life can join forces together with one thing in the mind that is to have fun and enjoy themselves. Jaaikaran has speer headed the project of educating the youth during the pandemic and helping Care homes. The pandemic was full of uncertainty and fear. Seeing and hearing the plights of many unfortunate children, he worked hard to help those he could. It is the value, that every person matters and the importance of mental wellbeing that laid the path of these projects. Jaaikaran aims to provide a different approach to philanthropy during through the medium of sport, education and learning. He believes that plays an important role in every child's life, whether it is to have fun, to bond with others, to find a passion and a profession.",
    },
  ];

  return (
    <>
      <div
        className="hero-wrap"
        style={{
          backgroundImage: `url(/assets/images/s-2.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="overlay"></div>
        <div className="container text-center">
          <h1 className="mb-3 bread">Our Team</h1>
        </div>
      </div>
      <Container>
        <h2 className="text-center my-4">Meet Our Team</h2>
        <Row>
          {teamMembers.map((member) => (
            <Col key={member.id} md={3} className="mb-4">
              <Link
                to={`/team/${member.id}`}
                style={{ textDecoration: "none" }}
              >
                <Card>
                  <Card.Img
                    variant="top"
                    src={member.image}
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                  <Card.Body>
                    <Card.Title className="text-center text-primary">
                      {member.name}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Team;
