import React from "react";
import Form from "./Form";

const Causes = () => {
  return (
    <div>
      <div
        class="hero-wrap"
        // style="background-image: url('images/bg_5.jpg');"
        style={{
          backgroundImage: `url(../assets/images/bg_5.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7  text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <p
                class="breadcrumbs"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <span class="mr-2">
                  <a href="index.html">Home</a>
                </span>{" "}
                <span>Causes</span>
              </p>
              <h1
                class="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                Causes
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section class="ftco-section">
        <div class="container">
          <div class="row">
            <div class="col-md-4 ">
              <div class="cause-entry">
                <a
                  href="#"
                  class="img"
                  //   style="background-image: url(images/cause-1.jpg);"
                  style={{
                    backgroundImage: `url(../assets/images/cause-1.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                  }}
                ></a>
                <div class="text p-3 p-md-4">
                  <h3>
                    <a href="#">Clean water for the urban area</a>
                  </h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic life
                  </p>
                  <span class="donation-time mb-3 d-block">
                    Last donation 1w ago
                  </span>
                  <div class="progress custom-progress-success">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "28%" }}
                      aria-valuenow="28"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="fund-raised d-block">
                    $12,000 raised of $30,000
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="cause-entry">
                <a
                  href="#"
                  class="img"
                  //   style="background-image: url(images/cause-2.jpg);"
                  style={{
                    backgroundImage: `url(../assets/images/cause-2.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                  }}
                ></a>
                <div class="text p-3 p-md-4">
                  <h3>
                    <a href="#">Clean water for the urban area</a>
                  </h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic life
                  </p>
                  <span class="donation-time mb-3 d-block">
                    Last donation 1w ago
                  </span>
                  <div class="progress custom-progress-success">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "28%" }}
                      aria-valuenow="28"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="fund-raised d-block">
                    $12,000 raised of $30,000
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="cause-entry">
                <a
                  href="#"
                  class="img"
                  //   style="background-image: url(images/cause-3.jpg);"
                  style={{
                    backgroundImage: `url(../assets/images/cause-3.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                  }}
                ></a>
                <div class="text p-3 p-md-4">
                  <h3>
                    <a href="#">Clean water for the urban area</a>
                  </h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic life
                  </p>
                  <span class="donation-time mb-3 d-block">
                    Last donation 1w ago
                  </span>
                  <div class="progress custom-progress-success">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "28%" }}
                      aria-valuenow="28"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="fund-raised d-block">
                    $12,000 raised of $30,000
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="cause-entry">
                <a
                  href="#"
                  class="img"
                  //   style="background-image: url(images/cause-4.jpg);"
                  style={{
                    backgroundImage: `url(../assets/images/cause-4.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                  }}
                ></a>
                <div class="text p-3 p-md-4">
                  <h3>
                    <a href="#">Clean water for the urban area</a>
                  </h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic life
                  </p>
                  <span class="donation-time mb-3 d-block">
                    Last donation 1w ago
                  </span>
                  <div class="progress custom-progress-success">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      //   style="width: 28%"
                      style={{ width: "28%" }}
                      aria-valuenow="28"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="fund-raised d-block">
                    $12,000 raised of $30,000
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="cause-entry">
                <a
                  href="#"
                  class="img"
                  //   style="background-image: url(images/cause-5.jpg);"
                  style={{
                    backgroundImage: `url(../assets/images/cause-5.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                  }}
                ></a>
                <div class="text p-3 p-md-4">
                  <h3>
                    <a href="#">Clean water for the urban area</a>
                  </h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic life
                  </p>
                  <span class="donation-time mb-3 d-block">
                    Last donation 1w ago
                  </span>
                  <div class="progress custom-progress-success">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "28%" }}
                      aria-valuenow="28"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="fund-raised d-block">
                    $12,000 raised of $30,000
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="cause-entry">
                <a
                  href="#"
                  class="img"
                  //   style="background-image: url(images/cause-6.jpg);"
                  style={{
                    backgroundImage: `url(../assets/images/cause-6.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                  }}
                ></a>
                <div class="text p-3 p-md-4">
                  <h3>
                    <a href="#">Clean water for the urban area</a>
                  </h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic life
                  </p>
                  <span class="donation-time mb-3 d-block">
                    Last donation 1w ago
                  </span>
                  <div class="progress custom-progress-success">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "28%" }}
                      aria-valuenow="28"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span class="fund-raised d-block">
                    $12,000 raised of $30,000
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col text-center">
              <div class="block-27">
                <ul>
                  <li>
                    <a href="#">&lt;</a>
                  </li>
                  <li class="active">
                    <span>1</span>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a href="#">4</a>
                  </li>
                  <li>
                    <a href="#">5</a>
                  </li>
                  <li>
                    <a href="#">&gt;</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Form />
    </div>
  );
};

export default Causes;
