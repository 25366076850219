import React from "react";
import { Link } from "react-router-dom";

const Howtohelpdetail = () => {
  return (
    <div>
      {/* Hero Section */}
      <div
        class="hero-wrap"
        style={{
          backgroundImage: `url(../assets/images/event_9.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7  text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <p
                class="breadcrumbs"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <span class="mr-2">
                  <a href="index.html">Home</a>
                </span>{" "}
                <span> How To Help</span>
              </p>
              <h1
                class="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                Volunteer, Donate & How to start your own foundation
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <section className="ftco-section ">
        <div className="container mx-auto mt-16 px-6 md:px-12">
          <div className="grid md:grid-cols-3 gap-10">
            <div className="relative overflow-hidden bg-white shadow-xl rounded-xl p-10 transition hover:shadow-2xl">
              <div className="flex flex-col items-center">
                <h2 className="text-3xl font-bold mb-3">Volunteer with us</h2>
                <p className="text-gray-600 text-left">
                  A helping hand is always welcome at Chanana Welfare
                  Foundation. We encourage you to join the movement and help
                  make lives around you better. If you have some time to spare
                  or are willing to take out time to work with the
                  underprivileged sections of the society, we are glad to put
                  you in touch with the right people.
                </p>
                <p className="text-gray-600 text-left">
                  Post the completion of your volunteer work with us, we shall
                  provide you a certificate of appreciation as a token of our
                  gratitude. If you wish to help in any area, drop us a mail at:
                  adim@chananawfoundation.org
                </p>
                <p className="text-gray-600 text-left">
                  We shall connect you directly to the NGOs that require
                  volunteers.
                </p>
              </div>
            </div>

            {/* Donate Section */}
            <div className="relative overflow-hidden bg-white shadow-xl rounded-xl p-10 transition hover:shadow-2xl">
              <div className="flex flex-col items-center">
                <h2 className="text-3xl font-bold mb-3">How To Donate</h2>
                <p className="text-gray-600 text-left">
                  Economic growth, change in belief systems and modernisation
                  are helping redefine the status of women and children in the
                  society. However, their economic situation, education and
                  health still remain a cause of concern. We at CWF remain
                  rooted to the cause and encourage others to help us shape a
                  better tomorrow for the girls and women in India. Donations
                  are our little way of sharing joy with the underprivileged. We
                  invite donations towards the cause and shall channelise them
                  through our various partners.
                </p>
                <p className="text-gray-600 text-left">
                  To make your donation, you can get in touch with Vijay Raj
                  Pandey at:
                </p>
                <p className="text-gray-600 text-left">
                  Address: Building No 147, 3rd Floor, New Manglapur, MG road,
                  New Delhi-110030,
                </p>
                <p className="text-gray-600 text-left">
                  Telephone :{" "}
                  <a
                    href="tel:+919773655235"
                    className="text-blue-500 hover:underline"
                  >
                    +91-9773655235
                  </a>
                </p>

                <p className="text-gray-600 text-left">
                  <a
                    href="mailto:adim@chananawfoundation.org"
                    className="text-blue-600 hover:underline"
                  >
                    adim@chananawfoundation.org
                  </a>
                </p>
              </div>
            </div>

            {/* Start a Foundation Section */}
            <div className="relative overflow-hidden bg-white shadow-xl rounded-xl p-10 transition hover:shadow-2xl">
              <div className="flex flex-col items-center">
                <h2 className="text-3xl font-bold mb-3">
                  How to start your own foundation
                </h2>
                <p className="text-gray-600 text-left">
                  Chanana Welfare Foundation aims at inspiring others in
                  bringing about a change. We would like to help other
                  individuals and businesses doing such work. Below are the
                  legal steps to start your own foundation:
                </p>
                <p className="text-gray-600 text-left">
                  1.Choose your foundation’s name and nominate the trustees.
                </p>
                <p className="text-gray-600 text-left">
                  2.A Private/Public Trust has to be registered.
                </p>
                <p className="text-gray-600 text-left">
                  3.To Register a Trust:
                </p>
                <p className="text-gray-600 text-left">
                  → Make an application for the registration of a Public/Private
                  trust
                </p>
                <p className="text-gray-600 text-left">
                  → Make a cover letter for the registration of the trust
                </p>
                <p className="text-gray-600 text-left">
                  {" "}
                  → A court stamp of Rs. 2/ is required
                </p>
                <p className="text-gray-600 text-left">
                  → The application is to be submitted to the Deputy or
                  Assistant Charity Commissioner of the region or the sub region
                </p>
                <p className="text-gray-600 text-left">
                  → The trust should have an office for administration work
                </p>
                <p className="text-gray-600 text-left">
                  → The application should be made under sub-section (1) and
                  should be signed by the trustee or his/her agent duly
                  authorised.
                </p>
                <p className="text-gray-600 text-left">
                  → This application is to be submitted to the Deputy or
                  Assistant Charity Commissioner of the region, who, on receipt
                  of the application shall approve the application. He/She will
                  endorse a certificate containing the word "Registered"
                  together with the number and the pages of the book in which
                  the document has been copied and forward the same to trustee.
                </p>
                <p className="text-gray-600 text-left">
                  → TIssuance of certificate of Registration in the prescribed
                  format.
                </p>
                <p className="text-gray-600 text-left">
                  Post the completion of your volunteer work with us, we shall
                  provide you a certificate of appreciation as a token of our
                  gratitude. If you wish to help in any area, drop us a mail at:
                  adim@chananawfoundation.org
                </p>
                <p className="text-gray-600 text-left"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Howtohelpdetail;
