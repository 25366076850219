import React, { useEffect, useState } from "react";
import axios from "axios";

const Images = () => {
  const [gallery, setGallery] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    axios
      .get("https://weplayfortheworld.org/api/api/gallery/")
      .then((response) => {
        setGallery(response.data);
      })
      .catch((error) => {
        console.error("Error fetching gallery:", error);
      });
  }, []);

  const openLightbox = (media, type) => {
    setSelectedMedia(media);
    setSelectedType(type);
    document.body.style.overflow = "hidden";
  };

  const closeLightbox = () => {
    setSelectedMedia(null);
    setSelectedType("");
    document.body.style.overflow = "unset";
  };

  return (
    <div>
      <section className="ftco-section ftco-gallery">
        <div>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
            {gallery
              .slice()
              .reverse()
              .map((item) => (
                <div
                  key={item.id}
                  onClick={() =>
                    openLightbox(
                      item.media,
                      item.media.endsWith(".mp4") ? "video" : "image"
                    )
                  }
                  style={{
                    flex: "1 0 300px",
                    height: "250px",
                    position: "relative",
                    cursor: "pointer",
                    overflow: "hidden",
                    backgroundImage: item.media.endsWith(".mp4")
                      ? "none"
                      : `url(${item.media})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className="gallery-item"
                >
                  {item.media.endsWith(".mp4") && (
                    <video
                      src={item.media}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      muted
                      loop
                      autoPlay
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </section>

      {selectedMedia && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={closeLightbox}
        >
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "white",
              fontSize: "30px",
              cursor: "pointer",
            }}
          >
            ×
          </div>
          {selectedType === "image" ? (
            <img
              src={selectedMedia}
              alt="Enlarged view"
              style={{
                maxHeight: "90vh",
                maxWidth: "90vw",
                objectFit: "contain",
              }}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <video
              src={selectedMedia}
              controls
              autoPlay
              style={{
                maxHeight: "90vh",
                maxWidth: "90vw",
                objectFit: "contain",
              }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Images;
