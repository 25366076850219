import React from "react";

const About = () => {
  return (
    <div>
      <div
        className="hero-wrap"
        style={{
          backgroundImage: `url(../assets/images/s-1.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div
            className="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-7 text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <p
                className="breadcrumbs"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <span className="mr-2">
                  <a href="index.html">Home</a>
                </span>
                <span>About</span>
              </p>
              <h1
                className="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                About Us
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-6 d-flex">
              <div
                className="img img-about align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/s-3.jpg)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                }}
              ></div>
            </div>
            <div className="col-md-6 pl-md-5">
              <h2 className="mb-4">
                Welcome to Chanana Welfare Foundation, Established Since 2015
              </h2>
              <p>
                Chanana Welfare Foundation collaborated with Youthreach in June
                2015 to implement various projects focusing on women
                empowerment, sanitation, and hygiene as part of Youthreach’s
                Community Development Project in Bawal (Haryana) and Roorkee
                (Uttarakhand).
              </p>
              <p>
                These initiatives aimed to uplift underprivileged communities by
                providing women with skill development opportunities, financial
                literacy training, and vocational programs. Additionally,
                sanitation and hygiene campaigns were conducted to promote
                better health and living conditions in these regions.
              </p>
              <p>
                The project also emphasized the importance of education,
                healthcare, and social awareness, ensuring that local
                communities received the necessary resources to improve their
                overall well-being.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Vision & Mission Section */}
      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section text-center">
              <h2 className="mb-4">Our Vision & Mission</h2>
            </div>
          </div>
          <div className="row">
            {/* Vision Section */}
            <div className="col-md-6">
              <h3 className="mb-3">Our Vision</h3>
              <p>
                Chanana Welfare Foundation (CWF) is committed to empowering
                women and children through education and hygiene. Our vision
                aligns with the Prime Minister’s{" "}
                <strong>Swachh Bharat Abhiyan</strong>, advocating for a cleaner
                and healthier India. At CWF, we believe that education and
                empowerment can drive significant societal change.
              </p>
              <p>
                Women play a crucial role in the country’s economy. We strive to
                unlock their potential by providing opportunities that ensure
                financial independence and empowerment. By facilitating access
                to education, we aim to uplift not just women, but their
                families and communities as well. Our goal is to ignite hope,
                ambition, and confidence, enabling women to find their voice in
                society.
              </p>
            </div>

            {/* Mission Section */}
            <div className="col-md-6">
              <h3 className="mb-3">Our Mission</h3>
              <p>
                CWF is dedicated to supporting children’s education and helping
                women acquire vocational skills to become self-sufficient. Our
                mission is to remove obstacles that hinder access to education
                and provide essential resources that foster learning and
                personal development.
              </p>
              <p>
                Through various initiatives, we create financial opportunities
                and prioritize employing women in certain projects to promote
                gender equality. Our work empowers women to break societal
                constraints, engage more with their communities, and lead
                dignified lives. With the support of our partners and
                volunteers, we continue to drive impactful change.
              </p>
              <p>
                Children are the foundation of a brighter future. CWF actively
                promotes education and hygiene awareness among children to
                reduce school dropout rates and support the government’s efforts
                in this area.
              </p>
              <p>
                <strong>Philanthropy is our way of life.</strong> We believe
                that collective efforts can transform society. To build a better
                future for India, every individual must come together and
                contribute towards a shared vision of progress.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
