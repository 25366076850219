import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

const teamMembers = [
  {
    id: 1,
    name: "Urshila Chanana",
    image: "../assets/images/urshila.jpeg",
    description:
      "Dr. Urshila Chanana, Trustee of CWF, is a retired Associate Professor of the Department of English, Indraprastha College, University of Delhi. Dr. Chanana’s passionate engagement with literature is representative of many contemporary social issues that beset the Indian milieu today. She has been deeply involved with the well-being of students from underprivileged sections of society.",
  },
  {
    id: 2,
    name: "Radhika Chanana",
    image: "../assets/images/radhika.jpg",
    description:
      "Radhika Chanana spearheads the Chanana Welfare Foundation and is the Founding Trustee. She envisions educating, empowering children and women, to propel a change that will bring in self sufficiency, poverty reduction and emancipation. She is a staunch believer in the fact that helping the less fortunate can move the nation towards equality and a bright, self-reliant future.",
  },
  {
    id: 3,
    name: "Amira Chanana",
    image: "../assets/images/amira.jpg",
    description:
      "Amira Chanana is an Advisor at CWF and helps her mother and grandmother in the work of the foundation. She is young, dynamic and the key force behind CWF. She grew up working with the underprivileged. So she understands the necessities and methods to help reduce the gap between the underprivileged and the privileged.",
  },
  {
    id: 4,
    name: "Jaaikaran Chanana",
    image: "../assets/images/jaaikaran.jpeg",
    description:
      "Jaaikaran Chanana is a Junior Trustee at CWF, who is passionate about football, food, the community and helping others. He was born in 2004, raised in New Delhi before attending Caldicott prep schoolUK, from 2015 to 2018 he is currently attending Wellington College school in the UK and will be graduating in 2023. Jaaikaran believes in the core values echoed by the other trustees. He believes literature, learning and sport all go hand in hand. It is his belief is that educating the youth gives them a larger perspective in life and helps them achieve their future goals. He believes that giving back should be the core value and principle of every single human being that they must encapsulate in their daily life. It brings great joy to Jaaikaran when he sees children enjoying themselves playing sport. Sport removes all boundaries that the class system has put in society as people from all backgrounds and life can join forces together with one thing in the mind that is to have fun and enjoy themselves. Jaaikaran has speer headed the project of educating the youth during the pandemic and helping Care homes. The pandemic was full of uncertainty and fear. Seeing and hearing the plights of many unfortunate children, he worked hard to help those he could. It is the value, that every person matters and the importance of mental wellbeing that laid the path of these projects. Jaaikaran aims to provide a different approach to philanthropy during through the medium of sport, education and learning. He believes that plays an important role in every child's life, whether it is to have fun, to bond with others, to find a passion and a profession.",
  },
];

const TeamDetail = () => {
  const { id } = useParams();
  const member = teamMembers.find((m) => m.id === parseInt(id));

  if (!member) {
    return <h2 className="text-center">Team member not found</h2>;
  }

  return (
    <div className="mt-5">
      <Container className="text-center  teamdetail">
        <h1 className="mt-5 text-center ">Our Team</h1>
        <img
          src={member.image}
          alt={member.name}
          style={{ width: "50%", borderRadius: "10px" }}
        />
        <h2 className="mt-3">{member.name}</h2>
        <p className="mt-3">{member.description}</p>
      </Container>
    </div>
  );
};

export default TeamDetail;
