import React, { useState } from "react";
import { Carousel, Modal } from "react-bootstrap";

const Hero = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <Carousel
        fade
        interval={3000}
        controls={true}
        indicators={true}
        pause={false}
        ride="carousel"
        style={{ height: "100vh" }}
      >
        {[
          "hero/s-3.jpg",
          "hero/CWF_1.jpeg",
          // "hero/p5-10.jpg",
          // "hero/p4-17.jpg",
          "hero/CWF_2.jpeg",
          "hero/CWF_3.jpeg",
          // "hero/event_7.jpeg",
          // "hero/p1-2.jpg",
          "hero/4.jpg",
        ].map((image, index) => (
          <Carousel.Item
            key={index}
            style={{ height: "100vh", position: "relative" }}
          >
            <img
              className="d-block w-100 h-100"
              src={`/assets/images/${image}`}
              alt={`Slide ${index + 1}`}
              style={{ objectFit: "cover" }}
            />
            {/* Overlay */}
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.2)",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            ></div>

            <Carousel.Caption>
              <button
                className="btn btn-white btn-outline-white px-4 py-3"
                onClick={() => setShowVideo(true)}
              >
                <span className="icon-play mr-2"></span>Watch Video
              </button>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Video Modal */}
      <Modal show={showVideo} onHide={() => setShowVideo(false)} centered>
        <Modal.Body className="p-0 position-relative">
          <button
            type="button"
            className="btn-close position-absolute"
            onClick={(e) => {
              e.stopPropagation();
              setShowVideo(false);
            }}
            aria-label="Close"
            style={{
              top: "10px",
              right: "10px",
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "10px",
              border: "none",
              cursor: "pointer",
              color: "red",
            }}
          >
            X
          </button>

          <video width="100%" height="auto" controls autoPlay>
            <source
              src={process.env.PUBLIC_URL + "/assets/images/home.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Hero;
