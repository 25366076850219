// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import axios from "axios";

// const options = {
//   items: 3,
//   loop: true,
//   margin: 10,
//   autoplay: true,
//   autoplayTimeout: 3000,
//   autoplayHoverPause: true,
//   dots: true, // Add this line for indicators
//   responsive: {
//     0: {
//       items: 1,
//     },
//     576: {
//       items: 2,
//     },
//     768: {
//       items: 3,
//     },
//     1024: {
//       items: 4,
//     },
//   },
// };

// const Eventcrousel = () => {
//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     axios
//       .get("https://alpha.saaskin.com/api/api/events/")
//       .then((response) => {
//         setEvents(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching events:", error);
//       });
//   }, []);

//   return (
//     <section className="ftco-section bg-light">
//       <div className="container-fluid">
//         <div className="row justify-content-center mb-5 pb-3">
//           <div className="col-md-5 heading-section text-center">
//             <h2 className="mb-4">Our Events</h2>
//             <p>
//               Stay updated with our latest events happening in different
//               locations.
//             </p>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-12">
//             <OwlCarousel className="carousel-cause owl-carousel" {...options}>
//               {events.map((event) => (
//                 <div className="item" key={event.id}>
//                   <div className="cause-entry">
//                     <Link
//                       to="#"
//                       className="img"
//                       style={{
//                         backgroundImage: `url(${event.image})`,
//                         backgroundSize: "cover",
//                         backgroundPosition: "center",
//                         backgroundRepeat: "no-repeat",
//                       }}
//                     ></Link>
//                     <div className="text p-3 p-md-4">
//                       <h3>
//                         <Link to="#">{event.title}</Link>
//                       </h3>
//                       <p>{event.description}</p>
//                       <span className="donation-time mb-3 d-block">
//                         Location: {event.place} | Date:{" "}
//                         {new Date(event.date).toLocaleDateString()}
//                       </span>
//                       <span className="fund-raised d-block">
//                         {event.category_name}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </OwlCarousel>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Eventcrousel;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import axios from "axios";

// const options = {
//   items: 3,
//   loop: true,
//   margin: 10,
//   autoplay: true,
//   autoplayTimeout: 3000,
//   autoplayHoverPause: true,
//   dots: true, // Enables indicators
//   responsive: {
//     0: {
//       items: 1,
//     },
//     576: {
//       items: 2,
//     },
//     768: {
//       items: 3,
//     },
//     1024: {
//       items: 4,
//     },
//   },
// };

// const EventCarousel = () => {
//   const [events, setEvents] = useState([]);
//   const [selectedMedia, setSelectedMedia] = useState(null);

//   useEffect(() => {
//     axios
//       .get("https://alpha.saaskin.com/api/api/events/")
//       .then((response) => {
//         setEvents(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching events:", error);
//       });
//   }, []);

//   const openLightbox = (image) => {
//     setSelectedMedia(image);
//   };

//   const closeLightbox = () => {
//     setSelectedMedia(null);
//   };

//   return (
//     <section className="ftco-section bg-light">
//       <div className="container-fluid">
//         <div className="row justify-content-center mb-5 pb-3">
//           <div className="col-md-5 heading-section text-center">
//             <h2 className="mb-4">Our Events</h2>
//             <p>
//               Stay updated with our latest events happening in different
//               locations.
//             </p>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-12">
//             <OwlCarousel className="carousel-cause owl-carousel" {...options}>
//               {events.map((event) => (
//                 <div className="item" key={event.id}>
//                   <div className="cause-entry">
//                     <div
//                       className="img"
//                       style={{
//                         backgroundImage: `url(${event.image})`,
//                         backgroundSize: "cover",
//                         backgroundPosition: "center",
//                         backgroundRepeat: "no-repeat",
//                         cursor: "pointer",
//                       }}
//                       onClick={() => openLightbox(event.image)}
//                     ></div>
//                     <div className="text p-3 p-md-4">
//                       <span className="donation-time mb-3 d-block">
//                         Location: {event.place} | Date:{" "}
//                         {new Date(event.date).toLocaleDateString()}
//                       </span>
//                       <h3>
//                         <Link to="#">{event.title}</Link>
//                       </h3>
//                       <p>{event.description}</p>

//                       <span className="fund-raised d-block">
//                         {event.category_name}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </OwlCarousel>
//           </div>
//         </div>
//       </div>

//       {/* Lightbox Modal */}
//       {selectedMedia && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundColor: "rgba(0,0,0,0.9)",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             zIndex: 1000,
//           }}
//           onClick={closeLightbox}
//         >
//           <div
//             style={{
//               position: "absolute",
//               top: "20px",
//               right: "20px",
//               color: "white",
//               fontSize: "30px",
//               cursor: "pointer",
//             }}
//             onClick={closeLightbox}
//           >
//             ×
//           </div>
//           <img
//             src={selectedMedia}
//             alt="Enlarged view"
//             style={{
//               maxHeight: "90vh",
//               maxWidth: "90vw",
//               objectFit: "contain",
//             }}
//             onClick={(e) => e.stopPropagation()}
//           />
//         </div>
//       )}
//     </section>
//   );
// };

// export default EventCarousel;

// import React, { useEffect, useState } from "react";

// const EventCarousel = () => {
//   const [events, setEvents] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedMedia, setSelectedMedia] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(
//          "https://weplayfortheworld.org/api/api/events/",
//           {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();
//         setEvents(data);
//       } catch (err) {
//         console.error("Error fetching events:", err);
//         setError("Failed to load events. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEvents();
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) =>
//         prevIndex === events.length - 1 ? 0 : prevIndex + 1
//       );
//     }, 3000);

//     return () => clearInterval(timer);
//   }, [events.length]);

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? events.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === events.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const openLightbox = (image) => {
//     setSelectedMedia(image);
//   };

//   const closeLightbox = () => {
//     setSelectedMedia(null);
//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "16rem",
//         }}
//       >
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div style={{ textAlign: "center", padding: "1rem", color: "#ef4444" }}>
//         {error}
//       </div>
//     );
//   }

//   return (
//     <section className="ftco-section bg-light">
//       <div className="container">
//         <div className="row justify-content-center mb-5">
//           <div className="col-md-5 text-center">
//             <h2 className="mb-4">Our Events</h2>
//             <p>
//               Stay updated with our latest events happening in different
//               locations.
//             </p>
//           </div>
//         </div>

//         <div className="position-relative">
//           <button
//             onClick={handlePrevious}
//             className="carousel-control prev"
//             style={{
//               position: "absolute",
//               left: "10px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             ←
//           </button>

//           <div className="carousel-container">
//             {events.length > 0 && (
//               <div
//                 className="cause-entry"
//                 style={{ margin: "0 auto", maxWidth: "400px" }}
//               >
//                 <div
//                   className="img"
//                   style={{
//                     backgroundImage: `url(${events[currentIndex]?.image})`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => openLightbox(events[currentIndex]?.image)}
//                 ></div>
//                 <div
//                   className="text p-3 p-md-4"
//                   style={{ background: "white", borderRadius: "0 0 4px 4px" }}
//                 >
//                   <span className="donation-time mb-3 d-block">
//                     Location: {events[currentIndex]?.place} | Date:{" "}
//                     {new Date(events[currentIndex]?.date).toLocaleDateString()}
//                   </span>
//                   <h3 style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
//                     {events[currentIndex]?.title}
//                   </h3>
//                   <p>{events[currentIndex]?.description}</p>
//                   <span
//                     className="fund-raised d-block"
//                     style={{ color: "#3b82f6", fontWeight: 500 }}
//                   >
//                     {events[currentIndex]?.category_name}
//                   </span>
//                 </div>
//               </div>
//             )}
//           </div>

//           <button
//             onClick={handleNext}
//             className="carousel-control next"
//             style={{
//               position: "absolute",
//               right: "10px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             →
//           </button>
//         </div>

//         {selectedMedia && (
//           <div
//             style={{
//               position: "fixed",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//               backgroundColor: "rgba(0,0,0,0.9)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               zIndex: 1000,
//             }}
//             onClick={closeLightbox}
//           >
//             <button
//               style={{
//                 position: "absolute",
//                 top: "1rem",
//                 right: "1rem",
//                 color: "white",
//                 fontSize: "2rem",
//                 background: "none",
//                 border: "none",
//                 cursor: "pointer",
//               }}
//               onClick={closeLightbox}
//             >
//               ×
//             </button>
//             <img
//               src={selectedMedia}
//               alt="Enlarged view"
//               style={{
//                 maxHeight: "90vh",
//                 maxWidth: "90vw",
//                 objectFit: "contain",
//               }}
//               onClick={(e) => e.stopPropagation()}
//             />
//           </div>
//         )}

//         <style>
//           {`
//             .spinner {
//               border: 4px solid rgba(0, 0, 0, 0.1);
//               width: 36px;
//               height: 36px;
//               border-radius: 50%;
//               border-left-color: #3b82f6;
//               animation: spin 1s linear infinite;
//             }

//             @keyframes spin {
//               0% {
//                 transform: rotate(0deg);
//               }
//               100% {
//                 transform: rotate(360deg);
//               }
//             }
//           `}
//         </style>
//       </div>
//     </section>
//   );
// };

// export default EventCarousel;

// import React, { useEffect, useState } from "react";

// const EventCarousel = () => {
//   const [events, setEvents] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedMedia, setSelectedMedia] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(
//          "https://weplayfortheworld.org/api/api/events/",
//           {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();
//         setEvents(data);
//       } catch (err) {
//         console.error("Error fetching events:", err);
//         setError("Failed to load events. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEvents();
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => {
//         const nextIndex = prevIndex + 4;
//         return nextIndex >= events.length ? 0 : nextIndex;
//       });
//     }, 5000);

//     return () => clearInterval(timer);
//   }, [events.length]);

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) => {
//       const nextIndex = prevIndex - 4;
//       return nextIndex < 0 ? Math.max(events.length - 3, 0) : nextIndex;
//     });
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => {
//       const nextIndex = prevIndex + 4;
//       return nextIndex >= events.length ? 0 : nextIndex;
//     });
//   };

//   const openLightbox = (image) => {
//     setSelectedMedia(image);
//   };

//   const closeLightbox = () => {
//     setSelectedMedia(null);
//   };

//   const getVisibleEvents = () => {
//     const visibleEvents = [];
//     for (let i = 0; i < 4; i++) {
//       const eventIndex = (currentIndex + i) % events.length;
//       if (eventIndex < events.length) {
//         visibleEvents.push(events[eventIndex]);
//       }
//     }
//     return visibleEvents;
//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "16rem",
//         }}
//       >
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div style={{ textAlign: "center", padding: "1rem", color: "#ef4444" }}>
//         {error}
//       </div>
//     );
//   }

//   return (
//     <section className="ftco-section bg-light">
//       <div className="container">
//         <div className="row justify-content-center mb-5">
//           <div className="col-md-5 text-center">
//             <h2 className="mb-4">Our Events</h2>
//             <p>
//               Stay updated with our latest events happening in different
//               locations.
//             </p>
//           </div>
//         </div>

//         <div className="position-relative">
//           <button
//             onClick={handlePrevious}
//             className="carousel-control prev"
//             style={{
//               position: "absolute",
//               left: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             ←
//           </button>

//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "repeat(4, 1fr)",
//               gap: "20px",
//               margin: "0 20px",
//             }}
//           >
//             {getVisibleEvents().map((event, index) => (
//               <div
//                 key={event.id}
//                 className="cause-entry"
//                 style={{
//                   background: "white",
//                   borderRadius: "4px",
//                   overflow: "hidden",
//                   boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//                 }}
//               >
//                 <div
//                   className="img"
//                   style={{
//                     backgroundImage: `url(${event.image})`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => openLightbox(event.image)}
//                 ></div>
//                 <div className="text p-3">
//                   <span
//                     className="donation-time mb-3 d-block"
//                     style={{ fontSize: "0.875rem", color: "#666" }}
//                   >
//                     Location: {event.place} | Date:{" "}
//                     {new Date(event.date).toLocaleDateString()}
//                   </span>
//                   <h3
//                     style={{
//                       fontSize: "1.25rem",
//                       marginBottom: "0.5rem",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {event.title}
//                   </h3>
//                   <p style={{ fontSize: "0.875rem", color: "#444" }}>
//                     {event.description}
//                   </p>
//                   <span
//                     className="fund-raised d-block"
//                     style={{
//                       color: "#3b82f6",
//                       fontWeight: 500,
//                       marginTop: "0.5rem",
//                     }}
//                   >
//                     {event.category_name}
//                   </span>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <button
//             onClick={handleNext}
//             className="carousel-control next"
//             style={{
//               position: "absolute",
//               right: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             →
//           </button>
//         </div>

//         {selectedMedia && (
//           <div
//             style={{
//               position: "fixed",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//               backgroundColor: "rgba(0,0,0,0.9)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               zIndex: 1000,
//             }}
//             onClick={closeLightbox}
//           >
//             <button
//               style={{
//                 position: "absolute",
//                 top: "1rem",
//                 right: "1rem",
//                 color: "white",
//                 fontSize: "2rem",
//                 background: "none",
//                 border: "none",
//                 cursor: "pointer",
//               }}
//               onClick={closeLightbox}
//             >
//               ×
//             </button>
//             <img
//               src={selectedMedia}
//               alt="Enlarged view"
//               style={{
//                 maxHeight: "90vh",
//                 maxWidth: "90vw",
//                 objectFit: "contain",
//               }}
//               onClick={(e) => e.stopPropagation()}
//             />
//           </div>
//         )}

//         <style>
//           {`
//             .spinner {
//               border: 4px solid rgba(0, 0, 0, 0.1);
//               width: 36px;
//               height: 36px;
//               border-radius: 50%;
//               border-left-color: #3b82f6;
//               animation: spin 1s linear infinite;
//             }

//             @keyframes spin {
//               0% {
//                 transform: rotate(0deg);
//               }
//               100% {
//                 transform: rotate(360deg);
//               }
//             }

//             @media (max-width: 1200px) {
//               .container {
//                 padding: 0 40px;
//               }
//             }

//             @media (max-width: 992px) {
//               div[style*="grid-template-columns"] {
//                 grid-template-columns: repeat(2, 1fr) !important;
//               }
//             }

//             @media (max-width: 576px) {
//               div[style*="grid-template-columns"] {
//                 grid-template-columns: repeat(1, 1fr) !important;
//               }
//             }
//           `}
//         </style>
//       </div>
//     </section>
//   );
// };

// export default EventCarousel;

// import React, { useEffect, useState } from "react";

// const EventCarousel = () => {
//   const [events, setEvents] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedMedia, setSelectedMedia] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(
//          "https://weplayfortheworld.org/api/api/events/",
//           {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();
//         setEvents(data);
//       } catch (err) {
//         console.error("Error fetching events:", err);
//         setError("Failed to load events. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEvents();
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
//     }, 5000);

//     return () => clearInterval(timer);
//   }, [events.length]);

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? events.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
//   };

//   const openLightbox = (image) => {
//     setSelectedMedia(image);
//   };

//   const closeLightbox = () => {
//     setSelectedMedia(null);
//   };

//   const getVisibleEvents = () => {
//     const visibleEvents = [];
//     for (let i = 0; i < 4; i++) {
//       const eventIndex = (currentIndex + i) % events.length;
//       visibleEvents.push(events[eventIndex]);
//     }
//     return visibleEvents;
//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "16rem",
//         }}
//       >
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div style={{ textAlign: "center", padding: "1rem", color: "#ef4444" }}>
//         {error}
//       </div>
//     );
//   }

//   return (
//     <section className="ftco-section bg-light">
//       <div className="container">
//         <div className="row justify-content-center mb-5">
//           <div className="col-md-5 text-center">
//             <h2 className="mb-4">Our Events</h2>
//             <p>
//               Stay updated with our latest events happening in different
//               locations.
//             </p>
//           </div>
//         </div>

//         <div className="position-relative">
//           <button
//             onClick={handlePrevious}
//             className="carousel-control prev"
//             style={{
//               position: "absolute",
//               left: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             ←
//           </button>

//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "repeat(4, 1fr)",
//               gap: "20px",
//               margin: "0 20px",
//               transition: "transform 0.3s ease",
//             }}
//           >
//             {getVisibleEvents().map((event, index) => (
//               <div
//                 key={`${event.id}-${index}`}
//                 className="cause-entry"
//                 style={{
//                   background: "white",
//                   borderRadius: "4px",
//                   overflow: "hidden",
//                   boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//                 }}
//               >
//                 <div
//                   className="img"
//                   style={{
//                     backgroundImage: `url(${event.image})`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => openLightbox(event.image)}
//                 ></div>
//                 <div className="text p-3">
//                   <span
//                     className="donation-time mb-3 d-block"
//                     style={{ fontSize: "0.875rem", color: "#666" }}
//                   >
//                     Location: {event.place} | Date:{" "}
//                     {new Date(event.date).toLocaleDateString()}
//                   </span>
//                   <h3
//                     style={{
//                       fontSize: "1.25rem",
//                       marginBottom: "0.5rem",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {event.title}
//                   </h3>
//                   <p
//                     style={{
//                       fontSize: "0.875rem",
//                       color: "#444",
//                       marginBottom: "0.5rem",
//                     }}
//                   >
//                     {event.description}
//                   </p>
//                   <span
//                     className="fund-raised d-block"
//                     style={{ color: "#3b82f6", fontWeight: 500 }}
//                   >
//                     {event.category_name}
//                   </span>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <button
//             onClick={handleNext}
//             className="carousel-control next"
//             style={{
//               position: "absolute",
//               right: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             →
//           </button>
//         </div>

//         {selectedMedia && (
//           <div
//             style={{
//               position: "fixed",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//               backgroundColor: "rgba(0,0,0,0.9)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               zIndex: 1000,
//             }}
//             onClick={closeLightbox}
//           >
//             <button
//               style={{
//                 position: "absolute",
//                 top: "1rem",
//                 right: "1rem",
//                 color: "white",
//                 fontSize: "2rem",
//                 background: "none",
//                 border: "none",
//                 cursor: "pointer",
//               }}
//               onClick={closeLightbox}
//             >
//               ×
//             </button>
//             <img
//               src={selectedMedia}
//               alt="Enlarged view"
//               style={{
//                 maxHeight: "90vh",
//                 maxWidth: "90vw",
//                 objectFit: "contain",
//               }}
//               onClick={(e) => e.stopPropagation()}
//             />
//           </div>
//         )}

//         <style>
//           {`
//             .spinner {
//               border: 4px solid rgba(0, 0, 0, 0.1);
//               width: 36px;
//               height: 36px;
//               border-radius: 50%;
//               border-left-color: #3b82f6;
//               animation: spin 1s linear infinite;
//             }

//             @keyframes spin {
//               0% {
//                 transform: rotate(0deg);
//               }
//               100% {
//                 transform: rotate(360deg);
//               }
//             }

//             @media (max-width: 1200px) {
//               .container {
//                 padding: 0 40px;
//               }
//             }

//             @media (max-width: 992px) {
//               div[style*="grid-template-columns"] {
//                 grid-template-columns: repeat(2, 1fr) !important;
//               }
//             }

//             @media (max-width: 576px) {
//               div[style*="grid-template-columns"] {
//                 grid-template-columns: repeat(1, 1fr) !important;
//               }
//               .carousel-control {
//                 display: none;
//               }
//             }
//           `}
//         </style>
//       </div>
//     </section>
//   );
// };

// export default EventCarousel;

// import React, { useEffect, useState } from "react";

// const EventCarousel = () => {
//   const [events, setEvents] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedMedia, setSelectedMedia] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(
//          "https://weplayfortheworld.org/api/api/events/",
//           {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();
//         setEvents(data);
//       } catch (err) {
//         console.error("Error fetching events:", err);
//         setError("Failed to load events. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEvents();
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
//     }, 5000);

//     return () => clearInterval(timer);
//   }, [events.length]);

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? events.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
//   };

//   const openLightbox = (image) => {
//     setSelectedMedia(image);
//   };

//   const closeLightbox = () => {
//     setSelectedMedia(null);
//   };

//   const getVisibleEvents = () => {
//     if (isMobile) {
//       return [events[currentIndex]];
//     }

//     const visibleEvents = [];
//     for (let i = 0; i < 4; i++) {
//       const eventIndex = (currentIndex + i) % events.length;
//       visibleEvents.push(events[eventIndex]);
//     }
//     return visibleEvents;
//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "16rem",
//         }}
//       >
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div style={{ textAlign: "center", padding: "1rem", color: "#ef4444" }}>
//         {error}
//       </div>
//     );
//   }

//   return (
//     <section className="ftco-section bg-light">
//       <div className="">
//         <div className="row justify-content-center mb-5">
//           <div className="col-md-5 text-center">
//             <h2 className="mb-4">Our Events</h2>
//             <p>
//               Stay updated with our latest events happening in different
//               locations.
//             </p>
//           </div>
//         </div>

//         <div className="position-relative">
//           <button
//             onClick={handlePrevious}
//             className="carousel-control prev"
//             style={{
//               position: "absolute",
//               left: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             ←
//           </button>

//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: isMobile ? "1fr" : "repeat(4, 1fr)",
//               gap: "20px",
//               margin: "0 20px",
//               transition: "all 0.3s ease",
//             }}
//           >
//             {getVisibleEvents().map((event, index) => (
//               <div
//                 key={`${event.id}-${index}`}
//                 className="cause-entry"
//                 style={{
//                   background: "white",
//                   borderRadius: "4px",
//                   overflow: "hidden",
//                   boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//                   maxWidth: isMobile ? "100%" : "none",
//                 }}
//               >
//                 <div
//                   className="img"
//                   style={{
//                     backgroundImage: `url(${event.image})`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => openLightbox(event.image)}
//                 ></div>
//                 <div className="text p-3">
//                   <span
//                     className="donation-time mb-3 d-block"
//                     style={{ fontSize: "0.875rem", color: "#666" }}
//                   >
//                     Location: {event.place} | Date:{" "}
//                     {new Date(event.date).toLocaleDateString()}
//                   </span>
//                   <h3
//                     style={{
//                       fontSize: "1.25rem",
//                       marginBottom: "0.5rem",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {event.title}
//                   </h3>
//                   <p
//                     style={{
//                       fontSize: "0.875rem",
//                       color: "#444",
//                       marginBottom: "0.5rem",
//                     }}
//                   >
//                     {event.description}
//                   </p>
//                   <span
//                     className="fund-raised d-block"
//                     style={{ color: "#3b82f6", fontWeight: 500 }}
//                   >
//                     {event.category_name}
//                   </span>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <button
//             onClick={handleNext}
//             className="carousel-control next"
//             style={{
//               position: "absolute",
//               right: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             →
//           </button>
//         </div>

//         {selectedMedia && (
//           <div
//             style={{
//               position: "fixed",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//               backgroundColor: "rgba(0,0,0,0.9)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               zIndex: 1000,
//             }}
//             onClick={closeLightbox}
//           >
//             <button
//               style={{
//                 position: "absolute",
//                 top: "1rem",
//                 right: "1rem",
//                 color: "white",
//                 fontSize: "2rem",
//                 background: "none",
//                 border: "none",
//                 cursor: "pointer",
//               }}
//               onClick={closeLightbox}
//             >
//               ×
//             </button>
//             <img
//               src={selectedMedia}
//               alt="Enlarged view"
//               style={{
//                 maxHeight: "90vh",
//                 maxWidth: "90vw",
//                 objectFit: "contain",
//               }}
//               onClick={(e) => e.stopPropagation()}
//             />
//           </div>
//         )}

//         <style>
//           {`
//             .spinner {
//               border: 4px solid rgba(0, 0, 0, 0.1);
//               width: 36px;
//               height: 36px;
//               border-radius: 50%;
//               border-left-color: #3b82f6;
//               animation: spin 1s linear infinite;
//             }

//             @keyframes spin {
//               0% {
//                 transform: rotate(0deg);
//               }
//               100% {
//                 transform: rotate(360deg);
//               }
//             }

//             @media (max-width: 1200px) {
//               .container {
//                 padding: 0 40px;
//               }
//             }

//             @media (max-width: 768px) {
//               .container {
//                 padding: 0 20px;
//               }
//             }
//           `}
//         </style>
//       </div>
//     </section>
//   );
// };

// export default EventCarousel;

// import React, { useEffect, useState } from "react";

// const EventCarousel = () => {
//   const [events, setEvents] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedMedia, setSelectedMedia] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(
//          "https://weplayfortheworld.org/api/api/events/",
//           {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();
//         setEvents(data);
//       } catch (err) {
//         console.error("Error fetching events:", err);
//         setError("Failed to load events. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchEvents();
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
//     }, 5000);

//     return () => clearInterval(timer);
//   }, [events.length]);

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? events.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
//   };

//   const handleDotClick = (index) => {
//     setCurrentIndex(index);
//   };

//   const openLightbox = (image) => {
//     setSelectedMedia(image);
//   };

//   const closeLightbox = () => {
//     setSelectedMedia(null);
//   };

//   const getVisibleEvents = () => {
//     if (isMobile) {
//       return [events[currentIndex]];
//     }

//     const visibleEvents = [];
//     for (let i = 0; i < 4; i++) {
//       const eventIndex = (currentIndex + i) % events.length;
//       visibleEvents.push(events[eventIndex]);
//     }
//     return visibleEvents;
//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "16rem",
//         }}
//       >
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div style={{ textAlign: "center", padding: "1rem", color: "#ef4444" }}>
//         {error}
//       </div>
//     );
//   }

//   return (
//     <section className="ftco-section bg-light">
//       <div className="">
//         <div className="row justify-content-center mb-5">
//           <div className="col-md-5 text-center">
//             <h2 className="mb-4">Our Events</h2>
//             <p>
//               Stay updated with our latest events happening in different
//               locations.
//             </p>
//           </div>
//         </div>

//         <div className="position-relative">
//           <button
//             onClick={handlePrevious}
//             className="carousel-control prev"
//             style={{
//               position: "absolute",
//               left: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             ←
//           </button>

//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: isMobile ? "1fr" : "repeat(4, 1fr)",
//               gap: "20px",
//               margin: "0 20px",
//               transition: "all 0.3s ease",
//             }}
//           >
//             {getVisibleEvents().map((event, index) => (
//               <div
//                 key={`${event.id}-${index}`}
//                 className="cause-entry"
//                 style={{
//                   background: "white",
//                   borderRadius: "4px",
//                   overflow: "hidden",
//                   boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//                   maxWidth: isMobile ? "100%" : "none",
//                 }}
//               >
//                 <div
//                   className="img"
//                   style={{
//                     backgroundImage: `url(${event.image})`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => openLightbox(event.image)}
//                 ></div>
//                 <div className="text p-3">
//                   <span
//                     className="donation-time mb-3 d-block"
//                     style={{ fontSize: "0.875rem", color: "#666" }}
//                   >
//                     Location: {event.place} | Date:{" "}
//                     {new Date(event.date).toLocaleDateString()}
//                   </span>
//                   <h3
//                     style={{
//                       fontSize: "1.25rem",
//                       marginBottom: "0.5rem",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {event.title}
//                   </h3>
//                   <p
//                     style={{
//                       fontSize: "0.875rem",
//                       color: "#444",
//                       marginBottom: "0.5rem",
//                     }}
//                   >
//                     {event.description}
//                   </p>
//                   <span
//                     className="fund-raised d-block"
//                     style={{ color: "#3b82f6", fontWeight: 500 }}
//                   >
//                     {event.category_name}
//                   </span>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <button
//             onClick={handleNext}
//             className="carousel-control next"
//             style={{
//               position: "absolute",
//               right: "-20px",
//               top: "50%",
//               transform: "translateY(-50%)",
//               zIndex: 10,
//               background: "white",
//               border: "none",
//               borderRadius: "50%",
//               width: "40px",
//               height: "40px",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//               cursor: "pointer",
//             }}
//           >
//             →
//           </button>

//           {/* Mobile Indicators */}
//           {isMobile && (
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 gap: "8px",
//                 marginTop: "20px",
//               }}
//             >
//               {events.map((_, index) => (
//                 <button
//                   key={index}
//                   onClick={() => handleDotClick(index)}
//                   style={{
//                     width: "8px",
//                     height: "8px",
//                     borderRadius: "50%",
//                     border: "none",
//                     background: currentIndex === index ? "#3b82f6" : "#cbd5e1",
//                     cursor: "pointer",
//                     padding: 0,
//                     transition: "background-color 0.3s ease",
//                   }}
//                   aria-label={`Go to slide ${index + 1}`}
//                 />
//               ))}
//             </div>
//           )}
//         </div>

//         {selectedMedia && (
//           <div
//             style={{
//               position: "fixed",
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0,
//               backgroundColor: "rgba(0,0,0,0.9)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               zIndex: 1000,
//             }}
//             onClick={closeLightbox}
//           >
//             <button
//               style={{
//                 position: "absolute",
//                 top: "1rem",
//                 right: "1rem",
//                 color: "white",
//                 fontSize: "2rem",
//                 background: "none",
//                 border: "none",
//                 cursor: "pointer",
//               }}
//               onClick={closeLightbox}
//             >
//               ×
//             </button>
//             <img
//               src={selectedMedia}
//               alt="Enlarged view"
//               style={{
//                 maxHeight: "90vh",
//                 maxWidth: "90vw",
//                 objectFit: "contain",
//               }}
//               onClick={(e) => e.stopPropagation()}
//             />
//           </div>
//         )}

//         <style>
//           {`
//             .spinner {
//               border: 4px solid rgba(0, 0, 0, 0.1);
//               width: 36px;
//               height: 36px;
//               border-radius: 50%;
//               border-left-color: #3b82f6;
//               animation: spin 1s linear infinite;
//             }

//             @keyframes spin {
//               0% {
//                 transform: rotate(0deg);
//               }
//               100% {
//                 transform: rotate(360deg);
//               }
//             }

//             @media (max-width: 1200px) {
//               .container {
//                 padding: 0 40px;
//               }
//             }

//             @media (max-width: 768px) {
//               .container {
//                 padding: 0 20px;
//               }
//             }
//           `}
//         </style>
//       </div>
//     </section>
//   );
// };

// export default EventCarousel;

import React, { useEffect, useState } from "react";

const EventCarousel = () => {
  const [events, setEvents] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://weplayfortheworld.org/api/api/events/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setEvents(data);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to load events. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [events.length]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? events.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const openLightbox = (image) => {
    setSelectedMedia(image);
  };

  const closeLightbox = () => {
    setSelectedMedia(null);
  };

  const getVisibleEvents = () => {
    if (isMobile) {
      return [events[currentIndex]];
    }

    const visibleEvents = [];
    for (let i = 0; i < 4; i++) {
      const eventIndex = (currentIndex + i) % events.length;
      visibleEvents.push(events[eventIndex]);
    }
    return visibleEvents;
  };

  // Calculate total number of slides/groups
  const getTotalGroups = () => {
    if (isMobile) {
      return events.length;
    }
    return Math.ceil(events.length / 4);
  };

  // Get current group index
  const getCurrentGroup = () => {
    if (isMobile) {
      return currentIndex;
    }
    return Math.floor(currentIndex / 4);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "16rem",
        }}
      >
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: "center", padding: "1rem", color: "#ef4444" }}>
        {error}
      </div>
    );
  }

  return (
    <section className="ftco-section bg-light">
      <div className="">
        <div className="row justify-content-center mb-5">
          <div className="col-md-5 text-center">
            <h2 className="mb-4">Our Events</h2>
            <p>
              Stay updated with our latest events happening in different
              locations.
            </p>
          </div>
        </div>

        <div className="position-relative">
          <button
            onClick={handlePrevious}
            className="carousel-control prev"
            style={{
              position: "absolute",
              left: "-20px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              background: "white",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              cursor: "pointer",
            }}
          >
            ←
          </button>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "repeat(4, 1fr)",
              gap: "20px",
              margin: "0 20px",
              transition: "all 0.3s ease",
            }}
          >
            {getVisibleEvents().map((event, index) => (
              <div
                key={`${event.id}-${index}`}
                className="cause-entry"
                style={{
                  background: "white",
                  borderRadius: "4px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  maxWidth: isMobile ? "100%" : "none",
                }}
              >
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${event.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    height: "200px",
                    cursor: "pointer",
                  }}
                  onClick={() => openLightbox(event.image)}
                ></div>
                <div className="text p-3">
                  {/* <span
                    className="donation-time mb-3 d-block"
                    style={{ fontSize: "0.875rem", color: "#666" }}
                  >
                    Location: {event.place} | Date:{" "}
                    {new Date(event.date).toLocaleDateString()}
                  </span> */}
                  <h3
                    // style={{
                    //   fontSize: "1.25rem",
                    //   marginBottom: "0.5rem",
                    //   fontWeight: "bold",
                    // }}
                    style={{
                      fontSize: "20px",
                      color: "#444",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {event.title}
                  </h3>
                  {/* <p
                    style={{
                      fontSize: "0.875rem",
                      color: "#444",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {event.description}
                  </p> */}
                  <span
                    className="fund-raised d-block"
                    style={{ color: "black", fontWeight: 500 }}
                  >
                    {event.category_name}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={handleNext}
            className="carousel-control next"
            style={{
              position: "absolute",
              right: "-20px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              background: "white",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              cursor: "pointer",
            }}
          >
            →
          </button>

          {/* Responsive Indicators */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              marginTop: "20px",
            }}
          >
            {[...Array(getTotalGroups())].map((_, index) => (
              <button
                key={index}
                onClick={() => handleDotClick(isMobile ? index : index * 4)}
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  border: "none",
                  background:
                    getCurrentGroup() === index ? "#3b82f6" : "#cbd5e1",
                  cursor: "pointer",
                  padding: 0,
                  transition: "background-color 0.3s ease",
                }}
                aria-label={`Go to ${isMobile ? "slide" : "group"} ${
                  index + 1
                }`}
              />
            ))}
          </div>
        </div>

        {selectedMedia && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.9)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
            onClick={closeLightbox}
          >
            <button
              style={{
                position: "absolute",
                top: "1rem",
                right: "1rem",
                color: "white",
                fontSize: "2rem",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={closeLightbox}
            >
              ×
            </button>
            <img
              src={selectedMedia}
              alt="Enlarged view"
              style={{
                maxHeight: "90vh",
                maxWidth: "90vw",
                objectFit: "contain",
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}

        <style>
          {`
            .spinner {
              border: 4px solid rgba(0, 0, 0, 0.1);
              width: 36px;
              height: 36px;
              border-radius: 50%;
              border-left-color: #3b82f6;
              animation: spin 1s linear infinite;
            }
            
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }

            @media (max-width: 1200px) {
              .container {
                padding: 0 40px;
              }
            }

            @media (max-width: 768px) {
              .container {
                padding: 0 20px;
              }
            }
          `}
        </style>
      </div>
    </section>
  );
};

export default EventCarousel;
