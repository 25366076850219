// import React from "react";

// const Contact = () => {
//   return (
//     <div>
//       <div
//         class="hero-wrap"
//         style={{
//           backgroundImage: `url(../assets/images/bg_2.jpg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           width: "100%",
//         }}
//         data-stellar-background-ratio="0.5"
//       >
//         <div class="overlay"></div>
//         <div class="container">
//           <div
//             class="row no-gutters slider-text align-items-center justify-content-center"
//             data-scrollax-parent="true"
//           >
//             <div
//               class="col-md-7  text-center"
//               data-scrollax=" properties: { translateY: '70%' }"
//             >
//               <p
//                 class="breadcrumbs"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 <span class="mr-2">
//                   <a href="index.html">Home</a>
//                 </span>{" "}
//                 <span>Contact</span>
//               </p>
//               <h1
//                 class="mb-3 bread"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 Contact Us
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>

//       <section class="ftco-section contact-section ftco-degree-bg">
//         <div class="container">
//           <div class="row d-flex mb-5 contact-info">
//             <div class="col-md-12 mb-4">
//               <h2 class="h4">Contact Information</h2>
//             </div>
//             <div class="w-100"></div>
//             <div class="col-md-4">
//               <p>
//                 <span>Address:</span> 216A, 1st Floor, The Gallery On M.G,
//                 Sultanpur, New Delhi-110030, India
//               </p>
//             </div>
//             <div class="col-md-4">
//               <p>
//                 <span>Phone:</span>{" "}
//                 <a href="tel:+911146109708">+91 11-46109708</a>
//               </p>
//             </div>
//             <div class="col-md-4">
//               <p>
//                 <span>Email:</span>{" "}
//                 <a href="mailto:admin@chananawfoundation.org">
//                   admin@chananawfoundation.org
//                 </a>
//               </p>
//             </div>

//           </div>
//           <div class="row block-9">
//             <div class="col-md-6 pr-md-5">
//               <h4 class="mb-4">Do you have any questions?</h4>
//               <form action="#">
//                 <div class="form-group">
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Your Name"
//                   />
//                 </div>
//                 <div class="form-group">
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Your Email"
//                   />
//                 </div>
//                 <div class="form-group">
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Subject"
//                   />
//                 </div>
//                 <div class="form-group">
//                   <textarea
//                     name=""
//                     id=""
//                     cols="30"
//                     rows="7"
//                     class="form-control"
//                     placeholder="Message"
//                   ></textarea>
//                 </div>
//                 <div class="form-group">
//                   <input
//                     type="submit"
//                     value="Send Message"
//                     class="btn btn-primary py-3 px-5"
//                   />
//                 </div>
//               </form>
//             </div>

//             <div class="col-md-6">
//               <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3173.014525229929!2d77.16441342495187!3d28.500765290053184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e41744ea3a5%3A0x91483bd21527b87e!2sThe%20Gallery%20On%20Mg%20Mall%2C%20New%20Manglapuri%2C%20Manglapuri%20Village%2C%20Sultanpur%2C%20New%20Delhi%2C%20Delhi%20110030!5e1!3m2!1sen!2sin!4v1738837654078!5m2!1sen!2sin"
//                 width="100%"
//                 height="500"
//                 allowfullscreen=""
//                 loading="lazy"
//               ></iframe>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Contact;

import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact_number: "",
    email: "",
    subject: "",
    message: "",
  });

  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //      "https://weplayfortheworld.org/api/api/contact/",
  //       formData
  //     );
  //     if (response.status === 201) {
  //       setStatus("Message sent successfully!");
  //       setFormData({
  //         name: "",
  //         contact_number: "",
  //         email: "",
  //         subject: "",
  //         message: "",
  //       });
  //     }
  //   } catch (error) {
  //     setStatus("Failed to send message. Please try again.");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://weplayfortheworld.org/api/api/contact/",
        formData
      );
      if (response.status === 201) {
        // Show success SweetAlert
        Swal.fire({
          icon: "success",
          title: "Message Sent!",
          text: "Your message has been sent successfully.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });

        // Reset form fields
        setFormData({
          name: "",
          contact_number: "",
          email: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      // Show error SweetAlert
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to send message. Please try again.",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div>
      <div
        className="hero-wrap"
        style={{
          backgroundImage: `url(../assets/images/event_3.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div className="row no-gutters slider-text align-items-center justify-content-center">
            <div className="col-md-7 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="/">Home</a>
                </span>{" "}
                <span>Contact</span>
              </p>
              <h1 className="mb-3 bread">Contact Us</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section contact-section ftco-degree-bg">
        <div className="container">
          <div class="row d-flex mb-5 contact-info">
            <div class="col-md-12 mb-4">
              <h2 class="h4">Contact Information</h2>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
              <p>
                <span>Address:</span> 216A, 1st Floor, The Gallery On M.G,
                Sultanpur, New Delhi-110030, India
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <span>Phone:</span>{" "}
                <a href="tel:+911146109708">+91 11-46109708</a>
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <span>Email:</span>{" "}
                <a href="mailto:admin@chananawfoundation.org">
                  admin@chananawfoundation.org
                </a>
              </p>
            </div>
          </div>
          <div className="row block-9">
            <div className="col-md-6 pr-md-5">
              <h4 className="mb-4">Do you have any questions?</h4>
              {status && <p>{status}</p>}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="contact_number"
                    className="form-control"
                    placeholder="Your Contact Number"
                    value={formData.contact_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control"
                    cols="30"
                    rows="7"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    className="btn btn-primary py-3 px-5"
                  />
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?..."
                width="100%"
                height="500"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
