// import React from "react";

// const Events = () => {
//   return (
//     <div>
//       <div
//         class="hero-wrap"
//         style={{
//           backgroundImage: `url(../assets/images/bg_1.jpg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           width: "100%",
//         }}
//         data-stellar-background-ratio="0.5"
//       >
//         <div class="overlay"></div>
//         <div class="container">
//           <div
//             class="row no-gutters slider-text align-items-center justify-content-center"
//             data-scrollax-parent="true"
//           >
//             <div
//               class="col-md-7  text-center"
//               data-scrollax=" properties: { translateY: '70%' }"
//             >
//               <p
//                 class="breadcrumbs"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 <span class="mr-2">
//                   <a href="index.html">Home</a>
//                 </span>{" "}
//                 <span>Event</span>
//               </p>
//               <h1
//                 class="mb-3 bread"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 Events
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>

//       <section class="ftco-section">
//         <div class="container">
//           <div class="row">
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <a
//                   href="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/event-1.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></a>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <a href="#">Sep. 10, 2018</a>
//                     </div>
//                     <div>
//                       <a href="#">Admin</a>
//                     </div>
//                     <div>
//                       <a href="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </a>
//                     </div>
//                   </div>
//                   <h3 class="heading mb-4">
//                     <a href="#">World Wide Donation</a>
//                   </h3>
//                   <p class="time-loc">
//                     <span class="mr-2">
//                       <i class="icon-clock-o"></i> 10:30AM-03:30PM
//                     </span>{" "}
//                     <span>
//                       <i class="icon-map-o"></i> Venue Main Campus
//                     </span>
//                   </p>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                   <p>
//                     <a href="event.html">
//                       Join Event <i class="ion-ios-arrow-forward"></i>
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <a
//                   href="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/event-2.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></a>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <a href="#">Sep. 10, 2018</a>
//                     </div>
//                     <div>
//                       <a href="#">Admin</a>
//                     </div>
//                     <div>
//                       <a href="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </a>
//                     </div>
//                   </div>
//                   <h3 class="heading mb-4">
//                     <a href="#">World Wide Donation</a>
//                   </h3>
//                   <p class="time-loc">
//                     <span class="mr-2">
//                       <i class="icon-clock-o"></i> 10:30AM-03:30PM
//                     </span>{" "}
//                     <span>
//                       <i class="icon-map-o"></i> Venue Main Campus
//                     </span>
//                   </p>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                   <p>
//                     <a href="event.html">
//                       Join Event <i class="ion-ios-arrow-forward"></i>
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <a
//                   href="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/event-3.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></a>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <a href="#">Sep. 10, 2018</a>
//                     </div>
//                     <div>
//                       <a href="#">Admin</a>
//                     </div>
//                     <div>
//                       <a href="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </a>
//                     </div>
//                   </div>
//                   <h3 class="heading mb-4">
//                     <a href="#">World Wide Donation</a>
//                   </h3>
//                   <p class="time-loc">
//                     <span class="mr-2">
//                       <i class="icon-clock-o"></i> 10:30AM-03:30PM
//                     </span>{" "}
//                     <span>
//                       <i class="icon-map-o"></i> Venue Main Campus
//                     </span>
//                   </p>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                   <p>
//                     <a href="event.html">
//                       Join Event <i class="ion-ios-arrow-forward"></i>
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <a
//                   href="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/event-4.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></a>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <a href="#">Sep. 10, 2018</a>
//                     </div>
//                     <div>
//                       <a href="#">Admin</a>
//                     </div>
//                     <div>
//                       <a href="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </a>
//                     </div>
//                   </div>
//                   <h3 class="heading mb-4">
//                     <a href="#">World Wide Donation</a>
//                   </h3>
//                   <p class="time-loc">
//                     <span class="mr-2">
//                       <i class="icon-clock-o"></i> 10:30AM-03:30PM
//                     </span>{" "}
//                     <span>
//                       <i class="icon-map-o"></i> Venue Main Campus
//                     </span>
//                   </p>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                   <p>
//                     <a href="event.html">
//                       Join Event <i class="ion-ios-arrow-forward"></i>
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <a
//                   href="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/event-5.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></a>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <a href="#">Sep. 10, 2018</a>
//                     </div>
//                     <div>
//                       <a href="#">Admin</a>
//                     </div>
//                     <div>
//                       <a href="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </a>
//                     </div>
//                   </div>
//                   <h3 class="heading mb-4">
//                     <a href="#">World Wide Donation</a>
//                   </h3>
//                   <p class="time-loc">
//                     <span class="mr-2">
//                       <i class="icon-clock-o"></i> 10:30AM-03:30PM
//                     </span>{" "}
//                     <span>
//                       <i class="icon-map-o"></i> Venue Main Campus
//                     </span>
//                   </p>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                   <p>
//                     <a href="event.html">
//                       Join Event <i class="ion-ios-arrow-forward"></i>
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <a
//                   href="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/event-6.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></a>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <a href="#">Sep. 10, 2018</a>
//                     </div>
//                     <div>
//                       <a href="#">Admin</a>
//                     </div>
//                     <div>
//                       <a href="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </a>
//                     </div>
//                   </div>
//                   <h3 class="heading mb-4">
//                     <a href="#">World Wide Donation</a>
//                   </h3>
//                   <p class="time-loc">
//                     <span class="mr-2">
//                       <i class="icon-clock-o"></i> 10:30AM-03:30PM
//                     </span>{" "}
//                     <span>
//                       <i class="icon-map-o"></i> Venue Main Campus
//                     </span>
//                   </p>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                   <p>
//                     <a href="event.html">
//                       Join Event <i class="ion-ios-arrow-forward"></i>
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="row mt-5">
//             <div class="col text-center">
//               <div class="block-27">
//                 <ul>
//                   <li>
//                     <a href="#">&lt;</a>
//                   </li>
//                   <li class="active">
//                     <span>1</span>
//                   </li>
//                   <li>
//                     <a href="#">2</a>
//                   </li>
//                   <li>
//                     <a href="#">3</a>
//                   </li>
//                   <li>
//                     <a href="#">4</a>
//                   </li>
//                   <li>
//                     <a href="#">5</a>
//                   </li>
//                   <li>
//                     <a href="#">&gt;</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Events;

// import React from "react";

// const Events = () => {
//   return (
//     <div>
//       <div
//         class="hero-wrap"
//         style={{
//           backgroundImage: `url(../assets/images/bg_1.jpg)`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           width: "100%",
//         }}
//         data-stellar-background-ratio="0.5"
//       >
//         <div class="overlay"></div>
//         <div class="container">
//           <div
//             class="row no-gutters slider-text align-items-center justify-content-center"
//             data-scrollax-parent="true"
//           >
//             <div
//               class="col-md-7  text-center"
//               data-scrollax=" properties: { translateY: '70%' }"
//             >
//               <p
//                 class="breadcrumbs"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 <span class="mr-2">
//                   <a href="index.html">Home</a>
//                 </span>{" "}
//                 <span>Event</span>
//               </p>
//               <h1
//                 class="mb-3 bread"
//                 data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
//               >
//                 Events
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>

//       <section class="ftco-section">
//         <div class="container">
//           <div class="row">
//             <div class="col-md-4 d-flex ">
//               <div class="blog-entry align-self-stretch">
//                 <a
//                   href="blog-single.html"
//                   class="block-20"
//                   style={{
//                     backgroundImage: `url(../assets/images/event-1.jpg)`,
//                     backgroundSize: "cover",
//                     backgroundPosition: "center",
//                     backgroundRepeat: "no-repeat",
//                     width: "100%",
//                   }}
//                 ></a>
//                 <div class="text p-4 d-block">
//                   <div class="meta mb-3">
//                     <div>
//                       <a href="#">Sep. 10, 2018</a>
//                     </div>
//                     <div>
//                       <a href="#">Admin</a>
//                     </div>
//                     <div>
//                       <a href="#" class="meta-chat">
//                         <span class="icon-chat"></span> 3
//                       </a>
//                     </div>
//                   </div>
//                   <h3 class="heading mb-4">
//                     <a href="#">World Wide Donation</a>
//                   </h3>
//                   <p class="time-loc">
//                     <span class="mr-2">
//                       <i class="icon-clock-o"></i> 10:30AM-03:30PM
//                     </span>{" "}
//                     <span>
//                       <i class="icon-map-o"></i> Venue Main Campus
//                     </span>
//                   </p>
//                   <p>
//                     A small river named Duden flows by their place and supplies
//                     it with the necessary regelialia.
//                   </p>
//                   <p>
//                     <a href="event.html">
//                       Join Event <i class="ion-ios-arrow-forward"></i>
//                     </a>
//                   </p>
//                 </div>
//               </div>
//             </div>

//           </div>
//           <div class="row mt-5">
//             <div class="col text-center">
//               <div class="block-27">
//                 <ul>
//                   <li>
//                     <a href="#">&lt;</a>
//                   </li>
//                   <li class="active">
//                     <span>1</span>
//                   </li>
//                   <li>
//                     <a href="#">2</a>
//                   </li>
//                   <li>
//                     <a href="#">3</a>
//                   </li>
//                   <li>
//                     <a href="#">4</a>
//                   </li>
//                   <li>
//                     <a href="#">5</a>
//                   </li>
//                   <li>
//                     <a href="#">&gt;</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Events;

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";

// const Events = () => {
//   const { categoryId } = useParams(); // Get category ID from URL
//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         const response = await axios.get(
//           `https://alpha.saaskin.com/api/api/events/${categoryId}/`
//         );
//         setEvents(response.data);
//       } catch (error) {
//         console.error("Error fetching events:", error);
//       }
//     };

//     fetchEvents();
//   }, [categoryId]); // Refetch when category ID changes

//   return (
//     <div>
//       <div
//         className="hero-wrap"
//         style={{ backgroundImage: `url(../assets/images/bg_1.jpg)` }}
//       >
//         <div className="overlay"></div>
//         <div className="container">
//           <div className="row no-gutters slider-text align-items-center justify-content-center">
//             <div className="col-md-7 text-center">
//               <p className="breadcrumbs">
//                 <span>
//                   <a href="/">Home</a>
//                 </span>{" "}
//                 <span>Event</span>
//               </p>
//               <h1 className="mb-3 bread">Events</h1>
//             </div>
//           </div>
//         </div>
//       </div>

//       <section className="ftco-section">
//         <div className="container">
//           <div className="row">
//             {events.length > 0 ? (
//               events.map((event) => (
//                 <div className="col-md-4 d-flex" key={event.id}>
//                   <div className="blog-entry align-self-stretch">
//                     <a
//                       href="#"
//                       className="block-20"
//                       style={{ backgroundImage: `url(${event.image})` }}
//                     ></a>
//                     <div className="text p-4 d-block">
//                       <div className="meta mb-3">
//                         <div>
//                           <a href="#">{new Date(event.date).toDateString()}</a>
//                         </div>
//                         <div>
//                           <i class="icon-map-o"></i>{" "}
//                           <a href="#">Location: {event.place}</a>
//                         </div>
//                       </div>
//                       <h3 className="heading mb-4">
//                         <a href="#">{event.title}</a>
//                       </h3>
//                       <p>{event.description}</p>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <p>No events available for this category.</p>
//             )}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Events;

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";

// const Events = () => {
//   const { categoryId } = useParams();
//   const [events, setEvents] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const eventsPerPage = 8; // Display 8 events per page

//   useEffect(() => {
//     const fetchEvents = async () => {
//       try {
//         const response = await axios.get(
//           `https://alpha.saaskin.com/api/api/events/${categoryId}/`
//         );
//         setEvents(response.data);
//       } catch (error) {
//         console.error("Error fetching events:", error);
//       }
//     };

//     fetchEvents();
//   }, [categoryId]);

//   // Calculate total pages
//   const totalPages = Math.ceil(events.length / eventsPerPage);

//   // Get current events
//   const indexOfLastEvent = currentPage * eventsPerPage;
//   const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
//   const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

//   // Handle page change
//   const handlePageChange = (pageNumber) => {
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   return (
//     <div>
//       {/* Hero Section */}
//       <div
//         className="hero-wrap"
//         style={{ backgroundImage: `url(../assets/images/bg_1.jpg)` }}
//       >
//         <div className="overlay"></div>
//         <div className="container">
//           <div className="row no-gutters slider-text align-items-center justify-content-center">
//             <div className="col-md-7 text-center">
//               <p className="breadcrumbs">
//                 <span>
//                   <a href="/">Home</a>
//                 </span>{" "}
//                 <span>Event</span>
//               </p>
//               <h1 className="mb-3 bread">Events</h1>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Events Section */}
//       <section className="ftco-section">
//         <div className="container">
//           <div className="row">
//             {currentEvents.length > 0 ? (
//               currentEvents.map((event) => (
//                 <div className="col-md-4 d-flex" key={event.id}>
//                   <div className="blog-entry align-self-stretch">
//                     <a
//                       href="#"
//                       className="block-20"
//                       style={{ backgroundImage: `url(${event.image})` }}
//                     ></a>
//                     <div className="text p-4 d-block">
//                       <div className="meta mb-3">
//                         <div>
//                           <a href="#">{new Date(event.date).toDateString()}</a>
//                         </div>
//                         <div>
//                           <i className="icon-map-o"></i>{" "}
//                           <a href="#">Location: {event.place}</a>
//                         </div>
//                       </div>
//                       <h3 className="heading mb-4">
//                         <a href="#">{event.title}</a>
//                       </h3>
//                       <p>{event.description}</p>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <p>No events available for this category.</p>
//             )}
//           </div>

//           {totalPages > 1 && (
//             <div className="row mt-5">
//               <div className="col text-center">
//                 <div className="block-27">
//                   <ul className="">
//                     {/* Previous Button */}
//                     <li className={currentPage === 1 ? "disabled" : ""}>
//                       <span
//                         onClick={() => handlePageChange(currentPage - 1)}
//                         style={{
//                           cursor: currentPage === 1 ? "not-allowed" : "pointer",
//                         }}
//                       >
//                         &lt;
//                       </span>
//                     </li>

//                     {/* Page Numbers */}
//                     {[...Array(totalPages)].map((_, index) => (
//                       <li
//                         key={index}
//                         className={currentPage === index + 1 ? "active" : ""}
//                       >
//                         <span onClick={() => handlePageChange(index + 1)}>
//                           {index + 1}
//                         </span>
//                       </li>
//                     ))}

//                     {/* Next Button */}
//                     <li
//                       className={currentPage === totalPages ? "disabled" : ""}
//                     >
//                       <span
//                         onClick={() => handlePageChange(currentPage + 1)}
//                         style={{
//                           cursor:
//                             currentPage === totalPages
//                               ? "not-allowed"
//                               : "pointer",
//                         }}
//                       >
//                         &gt;
//                       </span>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Events;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Events = () => {
  const { categoryId } = useParams();
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 9;
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `https://weplayfortheworld.org/api/api/events/${categoryId}/`
        );
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [categoryId]);

  const totalPages = Math.ceil(events.length / eventsPerPage);
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedMedia(imageUrl);
  };

  const closeLightbox = () => {
    setSelectedMedia(null);
  };

  return (
    <div>
      {/* Hero Section */}
      <div
        className="hero-wrap"
        style={{ backgroundImage: `url(../assets/images/event_10.jpeg)` }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div className="row no-gutters slider-text align-items-center justify-content-center">
            <div className="col-md-7 text-center">
              <p className="breadcrumbs">
                <span>
                  <a href="/">Home</a>
                </span>{" "}
                <span>Event</span>
              </p>
              <h1 className="mb-3 bread">Events</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Events Section */}
      <section className="ftco-section">
        <div className="container">
          <div className="row">
            {currentEvents.length > 0 ? (
              currentEvents.map((event) => (
                <div className="col-md-4 d-flex" key={event.id}>
                  <div className="blog-entry align-self-stretch">
                    {/* Clickable Image */}
                    <a
                      href="#"
                      className="block-20"
                      style={{ backgroundImage: `url(${event.image})` }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleImageClick(event.image);
                      }}
                    ></a>
                    <div className="text p-4 d-block">
                      {/* <div className="meta mb-3">
                        <div>
                          <a href="#">{new Date(event.date).toDateString()}</a>
                        </div>
                        <div>
                          <i className="icon-map-o"></i>{" "}
                          <a href="#">Location: {event.place}</a>
                        </div>
                      </div> */}
                      <h3 className="heading mb-4">
                        <a href="#">{event.title}</a>
                      </h3>
                      {/* <p>{event.description}</p> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No events available for this category.</p>
            )}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="row mt-5">
              <div className="col text-center">
                <div className="block-27">
                  <ul className="">
                    {/* Previous Button */}
                    <li className={currentPage === 1 ? "disabled" : ""}>
                      <span
                        onClick={() => handlePageChange(currentPage - 1)}
                        style={{
                          cursor: currentPage === 1 ? "not-allowed" : "pointer",
                        }}
                      >
                        &lt;
                      </span>
                    </li>

                    {/* Page Numbers */}
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={currentPage === index + 1 ? "active" : ""}
                      >
                        <span onClick={() => handlePageChange(index + 1)}>
                          {index + 1}
                        </span>
                      </li>
                    ))}

                    {/* Next Button */}
                    <li
                      className={currentPage === totalPages ? "disabled" : ""}
                    >
                      <span
                        onClick={() => handlePageChange(currentPage + 1)}
                        style={{
                          cursor:
                            currentPage === totalPages
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        &gt;
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {selectedMedia && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={closeLightbox}
        >
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              color: "white",
              fontSize: "30px",
              cursor: "pointer",
            }}
            onClick={closeLightbox}
          >
            ×
          </div>
          <img
            src={selectedMedia}
            alt="Enlarged view"
            style={{
              maxHeight: "90vh",
              maxWidth: "90vw",
              objectFit: "contain",
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default Events;
