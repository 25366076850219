import React from "react";
import { Link } from "react-router-dom";
import { FaHandsHelping, FaHandHoldingHeart } from "react-icons/fa";

const Howtohelp = () => {
  return (
    <div>
      {/* Hero Section */}
      <div
        class="hero-wrap"
        style={{
          backgroundImage: `url(../assets/images/event_7.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div
            class="row no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              class="col-md-7  text-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <p
                class="breadcrumbs"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <span class="mr-2">
                  <a href="index.html">Home</a>
                </span>{" "}
                <span> How To Help</span>
              </p>
              <h1
                class="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                How To Help
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* update design */}

      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section text-center">
              <h2 className="mb-4">How To Help</h2>
            </div>
          </div>
          <div className="row">
            {/* Vision Section */}
            <div className="col-md-6">
              <h3 className="mb-3">VOLUNTEER</h3>
              <p className="text-justify">
                A helping hand is always welcome at Chanana Welfare Foundation.
                We encourage you to join the movement and help make lives around
                you better. If you have some time to spare, or are willing to
                take out time to work with the underprivileged sections of the
                society, we are looking for you.
              </p>
              <Link
                to="/Howtohelpdetail"
                className="mt-5 bg-green-600 text-PRIMARY text-lg px-6 py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-green-700 transition"
              >
                Read More <span className="text-xl">→</span>
              </Link>
            </div>

            {/* Mission Section */}
            <div className="col-md-6">
              <h3 className="mb-3">DONATE</h3>
              <p className="text-justify">
                We at CWF encourage others to help us shape a better tomorrow
                for the girls and women in India. Donations are our little way
                of sharing joy with the underprivileged. We invite donations
                towards the cause and shall channelise them through our various
                partners.
              </p>
              <Link
                to="/Howtohelpdetail"
                className="mt-5 bg-green-600 text-PRIMARY text-lg px-6 py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-green-700 transition"
              >
                Read More <span className="text-xl">→</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Howtohelp;
