import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
const Blogdetail = () => {
  const { blogId } = useParams(); // Get blog ID from URL params
  const [blog, setBlog] = useState(null);
  const API_URL = `https://weplayfortheworld.org/api/api/blogs/${blogId}/`;

  useEffect(() => {
    axios
      .get(API_URL)
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog details:", error);
      });
  }, [API_URL, blogId]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div
        className="hero-wrap"
        style={{
          backgroundImage: `url(${blog.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
          <div className="row no-gutters slider-text align-items-center justify-content-center">
            <div className="col-md-7 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <Link to="/">Home</Link>
                </span>{" "}
                <span className="mr-2">
                  <Link to="/blog">Blog</Link>
                </span>{" "}
                <span>{blog.title}</span>
              </p>
              <h1 className="mb-3 bread">{blog.title}</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section ftco-degree-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2 className="mb-3 text-center">{blog.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(blog.description),
                }}
              ></div>
              {/* {blog.blogs && blog.blogs.length > 0 && (
                <div className="blog-images mt-5">
                  <h3 className="text-center mb-4">Blog Gallery</h3>
                  <div className="row">
                    {blog.blogs.map((item) => (
                      <div key={item.id} className="col-md-4 col-sm-6 mb-4">
                        <div className="card border-0 shadow-sm">
                          <div className="blog-image-container">
                            <img
                              src={item.image}
                              alt="Blog"
                              className="img-fluid rounded-top"
                            />
                          </div>
                          <div className="card-body text-center p-3">
                            <p className="blog-detail text-muted">
                              {item.detail}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
            </div>

            <div className="col-md-4 sidebar">
              <div className="sidebar-box">
                <h3>Recent Blogs</h3>
                {blog.related_blogs.map((recentblog) => (
                  <div key={recentblog.id} className="block-21 mb-4 d-flex">
                    <Link
                      to={`/Blogdetail/${recentblog.id}`}
                      className="blog-img mr-4"
                      style={{
                        backgroundImage: `url(${
                          recentblog.image
                            ? recentblog.image
                            : "/assets/images/default.jpg"
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></Link>
                    {/* <img
                      src={recentblog.image}
                      alt="Blog"  
                      className="img-fluid rounded-top"
                    /> */}
                    <div className="text">
                      <h3 className="heading">
                        <Link to={`/Blogdetail/${recentblog.id}`}>
                          {" "}
                          {recentblog.title}
                        </Link>
                      </h3>
                    </div>
                  </div>
                ))}
                {/* {[1, 2, 3].map((num) => (
                  <div key={num} className="block-21 mb-4 d-flex">
                    <Link
                      className="blog-img mr-4"
                      style={{
                        backgroundImage: `url('/assets/images/image_${num}.jpg')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></Link>
                    <div className="text">
                      <h3 className="heading">
                        <Link to="#">Recent Blog Title {num}</Link>
                      </h3>
                      <div className="meta">
                        <div>
                          <Link to="#">
                            <span className="icon-calendar"></span> July 12,
                            2018
                          </Link>
                        </div>
                        <div>
                          <Link to="#">
                            <span className="icon-person"></span> Admin
                          </Link>
                        </div>
                        <div>
                          <Link to="#">
                            <span className="icon-chat"></span> 19
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
              </div>
            </div>
          </div>
          {blog.blogs && blog.blogs.length > 0 && (
            <div className="blog-images mt-5">
              <h3 className="text-center mb-4">Blog Gallery</h3>
              <div className="row">
                {blog.blogs.map((item) => (
                  <div key={item.id} className="col-md-4 col-sm-6 mb-4">
                    <div className="card border-0 shadow-sm">
                      <div className="blog-image-container">
                        <img
                          src={item.image}
                          alt="Blog"
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="card-body text-center p-3">
                        <p className="blog-detail text-muted">{item.detail}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Blogdetail;
