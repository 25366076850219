import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch event categories from API
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://weplayfortheworld.org/api/api/categories/"
        );
        setCategories(response.data); // Store categories in state
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  return (
    <div class="ftco-footer ftco-section img">
      <div class="overlay"></div>
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-3">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2">About Us</h2>
              <p className="text-justify">
                Chanana Welfare Foundation collaborated with Youthreach in June
                2015 to implement various projects focusing on women
                empowerment, sanitation, and hygiene as part of Youthreach’s
                Community Development Project in Bawal (Haryana) and Roorkee
                (Uttarakhand).
              </p>
              <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                <li class="">
                  <Link to="#">
                    <span class="icon-twitter"></span>
                  </Link>
                </li>
                <li class="">
                  <Link to="#">
                    <span class="icon-facebook"></span>
                  </Link>
                </li>
                <li class="">
                  <Link to="#">
                    <span class="icon-instagram"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
         
          <div class="col-md-3">
            <div class="ftco-footer-widget mb-4 ml-md-4">
              <h2 class="ftco-heading-2">Site Links</h2>
              <ul class="list-unstyled">
                <li>
                  <Link to="/" class="py-2 d-block">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/Mission & Vision" class="py-2 d-block">
                    Mission & Vision
                  </Link>
                </li>

                <li>
                  <Link to="/Team" class="py-2 d-block">
                    Our Team
                  </Link>
                </li>
                <li>
                  <Link to="/Blog" class="py-2 d-block">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        
          <div className="col-md-3">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Event Categories</h2>
              <ul className="list-unstyled">
                {categories.length > 0 ? (
                  categories.map((category) => (
                    <li key={category.id}>
                      <Link
                        to={`/events/${category.id}`}
                        className="py-2 d-block"
                      >
                        {category.name}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li className="py-2 d-block">No Categories Available</li>
                )}
              </ul>
            </div>
          </div>
          <div class="col-md-3">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2">Contact</h2>
              <div class="block-23 mb-3">
                <ul>
                  <li>
                    <span class="icon icon-map-marker"></span>
                    <span class="text">
                      216A, 1st Floor, The Gallery On M.G, Sultanpur, New
                      Delhi-110030, India
                    </span>
                  </li>
                  <li>
                    <a
                      href="tel:+911146109708"
                      className="flex items-center text-gray-600 hover:text-blue-500"
                    >
                      <span className="icon icon-phone"></span>
                      <span className="text">+91 11-46109708</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:admin@chananawfoundation.org"
                      className="flex items-center text-gray-600 hover:text-blue-500"
                    >
                      <span className="icon icon-envelope"></span>
                      <span className="text">admin@chananawfoundation.org</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="row">
          <div class="col-md-12 text-center">
            <p>
              Copyright &copy;
              <script>document.write(new Date().getFullYear());</script> All
              rights reserved | This template is made with{" "}
              <i class="icon-heart" aria-hidden="true"></i> by{" "}
              <Link to="https://colorlib.com" target="_blank"></Link>
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
